<template>
  <BaseLayout>
    <SubHeader></SubHeader>
    <div class="container">
      <div class="d-flex justify-content-start flex-wrap" style="width: 500px; max-width: 100%; border: 1px solid #ddd; padding: 20px;">
        <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
          <strong>Selected Plan</strong>
          <router-link :to="{path: '/plans', query: {next_page: $route.query.next_page, next_id: $route.query.next_id}}" class="ml-auto" style="font-size: 12px;">Change Plan</router-link>
        </div>
        <div class="flex-fill" style="margin-top: 20px;">
          <div style="font-size: 18px;">{{current_plan.name}}</div>
          <div style="font-size: 16px;">$<span id="original-price-text">{{current_plan.prices ? current_plan.prices.monthly.price : 0}}</span><span id="discounted-price-text" style="font-weight: bold;"></span> / month</div>
        </div>
      </div>
      <div class="d-flex justify-content-start flex-wrap" style="width: 500px; max-width: 100%; margin-top: 16px;" v-if="$route.query.next_page !== 'trainer-package'">
        <div class="form-group" style="width: 100%;">
          <label>Coupon code</label>
          <div class="coupon-row" style="display: flex;">
            <a-form style="width: 100%;">
              <a-form-item :validate-status="searchingCoupon ? 'validating' : (invalidCoupon ? 'error' : 'success')" :help="invalidCoupon ? couponInvalidReason : ''" :has-feedback="couponApplied ? true : false">
                <a-input v-model:value="promo_code"  id="coupon-input" type="text" class="form-control" />
              </a-form-item>
            </a-form>
            <a-button type="primary" :loading="searchingCoupon" style="height: 38px; margin-left: 16px;" @click="applyPromo()">Apply</a-button>
          </div>
        </div>
      </div>
      <div style="text-align: center; margin: 20px 0;" v-if="loading"><a-spin /></div>
      <div v-if="!loading">
        <a-radio-group v-if="default_payment_method" v-model:value="use_default_payment_method">
          <a-radio class="radio-btn" :value="true">Use saved default card</a-radio>
          <a-radio class="radio-btn" :value="false">Add a new card</a-radio>
        </a-radio-group>
        <div v-if="use_default_payment_method">
          <fieldset style="margin-top: 40px;">
            <legend>Payment Details</legend>
            <div>
              <img src="../assets/img/card/amex.png" style="width: 25px" v-if="default_payment_method.card.brand == 'amex'" />
              <img src="../assets/img/card/diners.png" style="width: 25px" v-if="default_payment_method.card.brand == 'diners'" />
              <img src="../assets/img/card/discover.png" style="width: 25px" v-if="default_payment_method.card.brand == 'discover'" />
              <img src="../assets/img/card/jcb.png" style="width: 25px" v-if="default_payment_method.card.brand == 'jcb'" />
              <img src="../assets/img/card/mastercard.png" style="width: 25px" v-if="default_payment_method.card.brand == 'mastercard'" />
              <span v-if="default_payment_method.card.brand == 'unionpay'">UnionPay</span>
              <img src="../assets/img/card/visa.png" style="width: 25px" v-if="default_payment_method.card.brand == 'visa'" />
              **** **** **** {{ default_payment_method.card.last4 }}
            </div>
            <div style="margin: 0 0 10px 0; font-size: 0.9rem;">
              Exp.: <span v-if="default_payment_method.card.exp_month > 9">{{ default_payment_method.card.exp_month }}</span>
              <span v-if="default_payment_method.card.exp_month <= 9">0{{ default_payment_method.card.exp_month }}</span>
              <span>/ {{ default_payment_method.card.exp_year }}</span>
            </div>
          </fieldset>
          <fieldset style="margin-top: 20px;">
            <legend>Billing Information</legend>
            <div class="row">
              <div class="col">
                <p class="no-line-spacing"><span class="bold-text">{{ default_payment_method.billing_details.name }}</span></p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.email }}</p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.phone }}</p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.address.line1 }}</p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.address.city }}</p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.address.state }}</p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.address.postal_code }}</p>
                <p class="no-line-spacing">{{ default_payment_method.billing_details.address.country }}</p>
              </div>
            </div>
          </fieldset>
        </div>
        <div v-if="!use_default_payment_method">
          <fieldset style="margin-top: 40px;">
            <legend>Payment Details</legend>
            <div id="card-element" style="width: 500px; max-width: 100%; padding: 20px; border: 1px solid #ddd;"></div>
          </fieldset>
          <fieldset style="margin-top: 40px;">
            <legend>Billing Information</legend>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>First name</label>
                  <input type="text" class="form-control" v-model="billing_address.first_name" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Last name</label>
                  <input type="text" class="form-control" v-model="billing_address.last_name" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Email</label>
                  <input type="text" class="form-control" v-model="billing_address.email" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Phone</label>
                  <input type="text" class="form-control" v-model="billing_address.phone" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Address</label>
                  <input type="text" class="form-control" v-model="billing_address.address" />
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>City</label>
                  <input type="text" class="form-control" v-model="billing_address.city" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>State/Region/County</label>
                  <select v-model="billing_address.state" class="form-control">
                    <option :value="state.code" v-for="state in states" :key="state">{{state.name}}</option>
                  </select>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>Zip Code</label>
                  <input type="text" class="form-control" v-model="billing_address.zip_code" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>Country</label>
                  <select v-model="billing_address.country" class="form-control">
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="UK">United Kingdom</option>
                    <option value="IE">Ireland</option>
                  </select>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
        <div style="margin: 2rem 0;">
          <label>
            <input type="checkbox" v-model="charge_agree">
            I understand that by clicking "{{ action_button_name }}" my credit card will be charged every 30 days until I delete my {{ $route.query.next_page === 'trainer-package' ? 'subscription' : 'ad' }}.
          </label>
        </div>
        <div style="text-align: center; margin-top: 1rem;">
          <button class="btn btn-primary" @click="upgrade()" :disabled="submitting">{{ action_button_name }}</button>
        </div>
      </div>
    </div>
  </BaseLayout>
</template>

<script>
import axios from 'axios';
import BaseLayout from '@/layouts/Base.vue';
import SubHeader from '@/components/SubHeader.vue';
import plans from '@/constants/plans';
import {db} from "@/firebase";
import Swal from 'sweetalert2';
import AButton from 'ant-design-vue/lib/button';
import AInput from 'ant-design-vue/lib/input';
import AForm from 'ant-design-vue/lib/form';
import ARadio from 'ant-design-vue/lib/radio';
import ASpin from 'ant-design-vue/lib/spin';
import 'ant-design-vue/lib/input/style/index.css';
import 'ant-design-vue/lib/form/style/index.css';
import 'ant-design-vue/lib/radio/style/index.css';
import 'ant-design-vue/lib/spin/style/index.css';
import 'ant-design-vue/lib/button/style/index.css';
import payment_states from '@/constants/payment_states';

export default {
  components: {
    BaseLayout,
    SubHeader,
    AButton,
    AInput,
    AForm,
    ASpin,
    'a-radio': ARadio,
    'a-radio-group': ARadio.Group,
  },
  data() {
    return {
      loading: true,
      submitting: false,
      payment_method_id: null,
      subscription_id: null,
      stripe_public_key: process.env.VUE_APP_STRIPE_KEY,
      stripe: null,
      elements: null,
      card: null,
      current_plan: {},
      billing_address: {
        first_name: null,
        last_name: null,
        email: this.$store.state.user.email,
        phone: this.$store.state.user.phoneNumber,
        address: null,
        city: null,
        state: 'AL',
        zip_code: null,
        country: 'US'
      },
      default_payment_method: null,
      use_default_payment_method: false,
      promo_code: null,
      promo_code_changed: false,
      searchingCoupon: false,
      invalidCoupon: false,
      couponInvalidReason: 'Invalid coupon code',
      couponApplied: false,
      states: payment_states.US_STATES,
      charge_agree: false
    }
  },
  watch: {
    'billing_address.phone'(newValue) {
      if (newValue != null) {
        newValue = newValue.replace( /[^\d]/g, '' ).trim()
        if ( newValue.length > 0 ) {
          newValue = '+' + newValue;
        }
        this.billing_address.phone = newValue;
      }
    },
    'billing_address.country'(newValue) {
      if (newValue === 'US') {
        this.states = payment_states.US_STATES;
        this.billing_address.state = payment_states.US_STATES[0].code;
      } else if (newValue === 'CA') {
        this.states = payment_states.CANADA_STATES;
        this.billing_address.state = payment_states.CANADA_STATES[0].code;
      } else if (newValue === 'UK') {
        this.states = payment_states.UK_STATES;
        this.billing_address.state = payment_states.UK_STATES[0].code;
      } else if (newValue === 'IE') {
        this.states = payment_states.IRELAND_STATES;
        this.billing_address.state = payment_states.IRELAND_STATES[0].code;
      } else {
        this.states = [{ code: '-', name: '-' }];
        this.billing_address.state = '-';
      }
    },
    use_default_payment_method(newValue) {
      if (!newValue) {
        setTimeout(() => {
          this.configureStripe();
        })
      }
    },
    promo_code(newValue) {
      if (newValue != null && newValue.trim() != '') {
        this.promo_code_changed = true;
      } else {
        this.promo_code_changed = false;
      }
    }
  },
  computed: {
    action_button_name() {
      return this.$route.query.next_page == null ? 'Upgrade' : this.$route.query.next_page === 'trainer-package' ? 'Subscribe' : 'Publish Listing';
    }
  },
  async mounted() {
    let $this = this;
    document.title = 'Subscribe - Mane Street Market';
    window.scrollTo(0, 0);
    document.getElementById('discounted-price-text').textContent = '';
    this.current_plan = plans[this.$route.params.id];
    this.includeStripe('js.stripe.com/v3/', () => {
      $this.configureStripe();
    });
    this.getDefaultPaymentMethod();
  },
  methods: {
    includeStripe( URL, callback ){
      let documentTag = document, tag = 'script',
          object = documentTag.createElement(tag),
          scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = '//' + URL;
      if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false); }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    configureStripe() {
      if (document.getElementById('card-element') == null) {
        return;
      }
      this.stripe = Stripe(this.stripe_public_key);
      this.elements = this.stripe.elements();
      this.card = this.elements.create('card', {
        hidePostalCode: true
      });
      this.card.mount('#card-element');
    },
    async getDefaultPaymentMethod() {
      try {
        let response = await axios.get('/api/my-payment-methods');
        let defaultPm = response.data?.customer?.invoice_settings?.default_payment_method;
        if (response.data?.payment_methods) {
          for (let pm of response.data?.payment_methods) {
            if (pm.id === defaultPm) {
              this.default_payment_method = pm;
              this.use_default_payment_method = true;
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
        if (!this.use_default_payment_method) {
          setTimeout(() => {
            this.configureStripe();
          });
        }
      }
    },
    async upgrade() {
      if (!this.charge_agree) {
        Swal.fire({
          text: 'Please check the box to acknowledge that you understand this is a recurring charge every 30 days'
        });
        return;
      }
      if (this.promo_code_changed) {
        Swal.fire({
          title: 'Coupon code not applied',
          html: 'You have entered a code in the coupon field.<br><br>If you would like to use the coupon, please click the Apply button and then the Publish button.<br><br>Otherwise, please clear the coupon field.'
        });
        return;
      } else if (this.promo_code != null && this.promo_code.trim() == '') {
        this.promo_code = null;
      }
      const { first_name, last_name, email, phone, address, city, zip_code } = this.billing_address;
      if (!this.use_default_payment_method) {
        if ((first_name == null && last_name == null) || email == null || phone == null || address == null || city == null || zip_code == null) {
          Swal.fire({
            title: 'There was an error',
            text: 'Please fill billing address form!'
          });
          return;
        }
      }
      this.submitting = true;
      try {
        let currentPaymentMethod;
        if (!this.use_default_payment_method) {
          let result = await this.stripe.createPaymentMethod({
            type: 'card',
            card: this.card,
            billing_details: {
              name: this.billing_address.first_name + ' ' + this.billing_address.last_name,
              address: {
                city: this.billing_address.city,
                country: this.billing_address.country,
                line1: this.billing_address.address,
                postal_code: this.billing_address.zip_code,
                state: this.billing_address.state
              },
              email: this.billing_address.email,
              phone: this.billing_address.phone
            }
          });
          if (result.paymentMethod == null) {
            Swal.fire({
              title: 'There was an error',
              text: 'Please fill billing address form!'
            });
            console.log(result.error);
          }
          currentPaymentMethod = result.paymentMethod;
        } else {
          currentPaymentMethod = this.default_payment_method;
        }

        let response = await axios.post('/api/subscribe', {
          payment_method_id: currentPaymentMethod.id,
          package_name: this.current_plan.code,
          price_id: this.current_plan.prices.monthly.id,
          record_type: this.$route.query.next_page,
          record_id: this.$route.query.next_id,
          promo_code: this.promo_code
        });
        this.subscription_id = response.data.id;
        let userProfile = await db.collection('Users').doc(this.$store.state.user.uid).get()
        this.$store.dispatch('setProfile', userProfile.data());
        if (this.$route.query.next_page && this.$route.query.next_id) {
          this.$router.push(`/${this.$route.query.next_page}?id=${this.$route.query.next_id}&payment_completed=1`);
        } else if (this.$route.query.next_page) {
          this.$router.push(`/${this.$route.query.next_page}`);
        } else {
          this.$router.push('/my-listings');
        }
      } catch (err) {
        if (err.response?.data?.error?.code == 'card_declined') {
          Swal.fire({
            title: 'There was an error upgrading listing',
            text: 'The card has been declined'
          });
        } else if (err.response?.data?.error?.message) {
          Swal.fire({
            title: 'There was an error upgrading listing',
            text: err.response.data.error.message
          });
        } else {
          Swal.fire({
            title: 'There was an error upgrading listing',
            text: 'An unknown error has occurred'
          });
        }
      } finally {
        this.submitting = false;
      }
    },
    async applyPromo(promoCode = null, ignoreErrors = false) {
      document.getElementById('original-price-text').style.textDecoration = 'none';
      document.getElementById('discounted-price-text').textContent = '';
      if (!ignoreErrors) {
        this.searchingCoupon = true;
        this.couponApplied = true;
      }
      this.promo_code_changed = false;
      axios.get(`/api/check-promo?code=${ promoCode ? promoCode : this.promo_code }&type=${ this.$route.query.next_page }`).then(res => {
        this.invalidCoupon = false;
        let promo = res.data;
        if (!promo.active) {
          if (!ignoreErrors) {
            this.invalidCoupon = true;
            this.couponInvalidReason = 'The coupon that you have entered has expired';
          }
          return;
        } else if (promo.coupon.applies_to != null && !promo.coupon.applies_to.products.includes(this.current_plan.id)) {
          if (!ignoreErrors) {
            this.invalidCoupon = true;
            this.couponInvalidReason = 'This coupon is not applicable to the plan you selected';
          }
          return;
        }
        let coupon = promo.coupon;
        if (this.current_plan.prices) {
          if (coupon.amount_off) {
            document.getElementById('original-price-text').style.textDecoration = 'line-through';
            document.getElementById('discounted-price-text').textContent = ` $${ (this.current_plan.prices.monthly.price - coupon.amount_off).toFixed(2) }`;
          } else if (coupon.percent_off) {
            document.getElementById('original-price-text').style.textDecoration = 'line-through';
            document.getElementById('discounted-price-text').textContent = ` $${ (this.current_plan.prices.monthly.price * ((100 - coupon.percent_off) / 100)).toFixed(2) }`;
          }
        }
        this.couponApplied = true;
        this.promo_code = promoCode ? promoCode : this.promo_code;
      }).catch(err => {
        if (!ignoreErrors) {
          let errData = err.response.data;
          if (errData.error && errData.error.code === 2) { // not a valid coupon
            this.invalidCoupon = true;
            this.couponInvalidReason = errData.error.message ? errData.error.message : 'Invalid coupon code';
          } else {
            Swal.fire({
              title: 'There was an error',
              text: 'Coupon code cannot be used. Please try again'
            });
          }
        }
      }).finally(() => {
        this.searchingCoupon = false;
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .bold-text {
    font-weight: bold;
  }

  .no-line-spacing {
    margin: 0;
  }

  .radio-btn {
    display: block;
    height: 30px;
    line-height: 30px;
    font-size: 1rem;
  }
</style>
